import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

function Hamburger() {
	const naviContext = useContext(NaviContext)

	// Keeping track of Header Logo Width
	// useEffect(() => {
	// 	const logo = document.getElementsByClassName('master-logo')
	// 	const hamburger = document.getElementsByClassName('master-hamburger-container')
	// 	const offset = logo[0].clientWidth - hamburger[0].clientWidth
	// 	setLogoWidthOffset(offset)
	// }, [])

	
  return (
		<div
			className="master-hamburger"
			onClick={
				() => {
					naviContext.activeToggle(true)
					naviContext.hamburgerActiveToggle(true)
				}
			}
			onKeyDown={
				() => {
					naviContext.activeToggle(true)
					naviContext.hamburgerActiveToggle(true)
				}
			}
			role="button"
			tabIndex={0}
		>
			<div className="cover"></div>
			{/* <Transition
				in={naviContext.beforeHeaderBreakpoint ? true :	false}
				timeout={baseDuration}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
			>
				<Logo />
			</Transition> */}
			<div className="master-hamburger-positioner">
				<div style={{opacity: 1}} className="master-hamburger-container">
					<div className={`hamburger-container hamburger hamburger--close1 ${naviContext.isHamburgerActive ? 'open' : null }`}>
						<div className="hamburger__icon">
							<div className="hamburger__line hamburger__line--1"></div>
							<div className="hamburger__line hamburger__line--2"></div>
							<div className="hamburger__line hamburger__line--3"></div>
						</div>
						
						{/* { naviContext.isHamburgerActive ?
						<div className="starfall-wrap">
							<Starfall />
						</div>
						: null } */}
					</div>
				</div>
			</div>
		</div>
  )
}

export default Hamburger